import React, { useState, useEffect } from "react";
import image from "../../assets/images/subheader.jpg";
import CommonBreadcrumbs from "../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import image1 from "../../assets/images/date.png";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { fetchDailykathaData, fetchSlider } from "../../api/API";
import Loader from "../../common/Loader/Loader";
import InnerpageLoader from "../Home/InnerpageLoader";
import "./DailyKatha.css";
import FullpageLoader from "../../common/HomeSliderLoader/FullpageLoader";

const DailyKatha = () => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    <label color="text.primary" className="active-link-color">
      Daily Katha
    </label>,
  ];

  const [apiData, setApiData] = useState([]);
  const [kathaTitle, setKathaTitle] = useState([]);
  const [kathaDesc, setKathaDesc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [youTubeData, setYoutubeData] = useState();

  useEffect(() => {
    console.log("availableDates", availableDates);
  }, [availableDates]);
  const fetchData = async (date) => {
    setLoading(true);
    try {
      var data = {
        url: process.env.REACT_APP_API_URL,
        page: "daily",
        type: "katha",
      };
      if (date) {
        const formattedDate = date.format("MM/DD/YYYY");
        data["date"] = formattedDate;
      }

      const response = await fetchDailykathaData(data);
      const dailyData = response.data?.responseBody?.daily;
      if (dailyData && dailyData.length > 0) {
        const firstDailyItem = dailyData[0];
        if (firstDailyItem.date) {
          setSelectedDate(dayjs(firstDailyItem.date, "MM/DD/YYYY"));
        }
        setApiData(dailyData);

        const dates = response.data.responseBody?.available_dates || [];
        setAvailableDates(dates);
      } else {
        setSelectedDate(null);
        setApiData([]);

        setAvailableDates([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Convert YouTube URL to embedded iframe
  const getYoutubeEmbedUrl = (url) => {
    if (url) {
      // Regular expression to match YouTube URL patterns
      const youtubeRegex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s?]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

      const match = url.match(youtubeRegex);

      if (match && match[1]) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    return null;
  };

  const onChangeSelected = (newValue) => {
    fetchData(newValue);
  };
  // let currentDate = new Date();
  // console.log(currentDate);

  useEffect(() => {
    setLoading(true);
    const fetchBanner = async () => {
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "daily",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  //function to calculate the height of the iframe
  const calculateHeight = () => {
    // Assume a default aspect ratio of 16:9 for YouTube videos
    const aspectRatio = 9 / 16;

    // Calculate the width of the iframe based on the parent container's width
    const width = document.querySelector(".container").offsetWidth;

    // Calculate the height based on the aspect ratio
    const height = width * aspectRatio;

    return height;
  };
  const apiKey = "AIzaSyDEPqey9i1sZFnOGiiE1jqa4tLLH-VC4D8";
  // const abc = apiData.map((item)=>{
  //   console.log("item",item.media.media[0])
  //   return item
  // })
  useEffect(() => {
    const fetchVideoDetails = async (videoId) => {
      try {
        if (!videoId) return null;
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`
        );
        const data = await response.json();

        if (!data.items || data.items.length === 0) {
          return null;
        }

        const { title, thumbnails } = data.items[0].snippet;
        const thumbnailUrl = thumbnails.medium.url;
        return { title, thumbnailUrl };
      } catch (error) {
        console.error("Error fetching video details:", error);
        return null;
      }
    };

    const fetchAllVideoDetails = async () => {
      const videoPromises = apiData.map(async (item) => {
        console.log("item", item);
        const mediaArray = item.media.media;
        const firstMedia = mediaArray[0];
        const videoId = firstMedia.match(
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
        )?.[1];

        const videoData = await fetchVideoDetails(videoId);
        return { videoData, item };
      });

      const resolvedVideos = await Promise.all(videoPromises);
      setYoutubeData(
        resolvedVideos.filter((video) => video.videoData !== null)
      );
    };

    fetchAllVideoDetails();
  }, [apiData, apiKey]);

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      {!imageLoaded && <FullpageLoader />}
      <Container className="daily-katha">
        <div className="date-picker">
          <div className="date-picker-inner-wrap">
            <div className="date-picker-wrap">
              <div>
                <img src={image1} className="date-picker-img" alt="" />
              </div>
              <div>
                <h4>
                  {selectedDate
                    ? selectedDate.format("MMMM DD, YYYY")
                    : "No date selected"}
                </h4>
              </div>
            </div>
          </div>
          <div className="date-picker-daily-darshan">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                    onChangeSelected(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  shouldDisableDate={(day) => {
                    const formattedDay = dayjs(day).format("MM/DD/YYYY");
                    const availableDatesArray = Object.values(
                      availableDates
                    ).map((date) => date.replace(/\\\//g, "/"));
                    return !availableDatesArray.includes(formattedDay);
                  }}
                  className="katha-date-picker"
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <div className="darshan-details">
          <h6>{apiData?.title}</h6>
          <p>{apiData?.short_description}</p>
        </div>

        <div className="spinner-container">
          {loading && (
            <div className="spinner">
              <Loader />
            </div>
          )}
          {/* Iterate over each item in the daily array */}
          {youTubeData?.map((dailyItem, dailyIndex) => (
            <div key={dailyIndex}>
              <div
                className={`darshan-details ${
                  apiData.length > 1 ? "multiple-darshan" : ""
                }`}
              >
                <h6>{dailyItem?.videoData?.title}</h6>
                <p>{dailyItem?.item?.media?.short_description}</p>
              </div>
              <div className="daily-media-container">
                {/* Render the first media item in the iframe */}
                {dailyItem?.item?.media?.media &&
                  dailyItem?.item?.media?.media[0] && (
                    <iframe
                      width="100%"
                      height={calculateHeight()}
                      src={getYoutubeEmbedUrl(dailyItem?.item?.media?.media[0])}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="katha-video"
                    ></iframe>
                  )}

                <Container>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    className="daily-katha-video-wrap pt-5"
                  >
                    {dailyItem?.item?.media?.media &&
                      dailyItem?.item?.media?.media?.length > 1 &&
                      dailyItem?.item?.media?.media
                        .slice(1)
                        .map((mediaItem, mediaIndex) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={mediaIndex}
                            style={{ padding: "0px 0px 30px" }}
                          >
                            <Card className="daily-katha-videos">
                              <iframe
                                width="100%"
                                height="100"
                                src={getYoutubeEmbedUrl(mediaItem)}
                                title="YouTube video player"
                                frameBorder="0"
                              ></iframe>
                            </Card>
                          </Grid>
                        ))}
                  </Grid>
                </Container>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default DailyKatha;
