import React, { useState } from "react";
import HomeSlider from "./HomeSlider";
import Videos from "../Videos/Videos";
import Publication from "../Publication/Publication";
import Activities from "../Activities/Activities";
import SantPhotos from "../SantPhotos/SantPhotos";
import FullpageLoader from "../../common/HomeSliderLoader/FullpageLoader";
import "./Home.css";

const Home = () => {
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isPublicationLoaded, setIsPublicationLoaded] = useState(false);
  const [isActivityLoaded, setIsActivityLoaded] = useState(false);

  const allLoaded = isSliderLoaded;

  return (
    <div className="container-fluid slider-wrap">
      {!isSliderLoaded && <FullpageLoader />}
      <HomeSlider setIsSliderLoaded={setIsSliderLoaded} />
      <Videos />
      <Publication />
      <Activities />
      <SantPhotos />
    </div>
  );
};

export default Home;
