import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CommonBreadcrumbs from "../../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import { useParams } from "react-router-dom";
import { fetchSlider } from "../../../api/API";
import InnerpageLoader from "../../Home/InnerpageLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Aboustype1 = ({ aboutUsData }) => {
  const params = useParams();
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    { label: "About Us", url: "/about-us" },
    <label color="text.primary" className="active-link-color">
      {params.title}
    </label>,
  ];

  const { image, short_description, title } = aboutUsData.about_us_data;
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  // const handleImageLoad = () => {
  //   setImageLoaded(true);
  // };

  const handleImageLoad = (src) => {
    const imageElement = document.querySelector(
      `.lazy-load-image-background[data-src="${src}"]`
    );
    if (imageElement) {
      imageElement.classList.add("lazy-load-image-loaded");
    }
    setImageLoaded(true);
  };

  useEffect(() => {
    const fetchBanner = async () => {
      setLoading(true);
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "about us",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const leftData = [
    "Item 1",
    "Item 2",
    "Item 3",
    "Item 4",
    "Item 5",
    "Item 6",
    "Item 7",
    "Item 8",
  ];

  const rightData = ["Item A", "Item B", "Item C", "Item D"];

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      <Container className="about-us-page">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8}>
            {aboutUsData.about_desc.map((e, index) => {
              return (
                <div
                  className={`container about-container about-us-content aboutusType1 ${
                    index > 0 ? "aboutus-type1" : ""
                  }`}
                >
                  <div>
                    <span className="span-bg-color">{e.title}</span>
                  </div>
                  <div className=" pt-4">
                    {/* <img src={e.image} className="about-us-innerpage" /> */}
                    <LazyLoadImage
                      src={e.image}
                      alt=""
                      className="about-us-innerpage"
                      // effect="blur"
                      wrapperClassName="lazy-load-image-background aboutustype"
                      afterLoad={() => {
                        const image = document.querySelector(
                          `.lazy-load-image-background[data-src="${e.image}"]`
                        );
                        if (image) {
                          image.classList.add("lazy-load-image-loaded");
                        }
                      }}
                    />
                    <p
                      className="mt-5"
                      dangerouslySetInnerHTML={{
                        __html: e.content,
                      }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className="about-us-grid">
              <div className="container about about-us-content about_us_content">
                {/* <img src={image} alt="" className="about-image" /> */}
                <LazyLoadImage
                  src={image}
                  alt={image}
                  className="about-image"
                  wrapperClassName="lazy-load-image-background aboutustype"
                  afterLoad={() => handleImageLoad(image)}
                />
                <div className="about-text">
                  <h1 className="">{title}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: short_description,
                    }}
                  ></p>
                </div>
              </div>

              {aboutUsData.throns.map(
                (thorn, index) =>
                  thorn[0] !== "" && (
                    <div
                      key={index}
                      className="container about about-us-content"
                    >
                      <h1 className="about-text1">{thorn[0]}</h1>
                      <ul className="about-list">
                        {thorn.slice(1).map((element, i) => (
                          <li key={i}>{element.name}</li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Aboustype1;
