import React, { useState, useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import imageNotFound from "../../../assets/images/NoImageFound.jpg.png";
import { useNavigate } from "react-router-dom";
import CommonBreadcrumbs from "../../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import {
  fetchActivitiyList,
  fetchNavbarData,
  fetchSlider,
} from "../../../api/API";
import { useParams } from "react-router-dom";
import InnerpageLoader from "../../Home/InnerpageLoader";
import ActivityLoader from "../../../common/Loader/ActivityLoader";
import "./SubActivities.css";
import { TablePagination } from "@mui/material";
import FullpageLoader from "../../../common/HomeSliderLoader/FullpageLoader";
import { IoSearch } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SubActivities = () => {
  const [activities, setActivities] = useState("");
  const [year, setYear] = useState("All");
  const [apiData, setApiData] = useState([]);
  const [banner, setBanner] = useState([]);

  const [filterActivities, setFilterActivities] = useState([]);
  const [filterYear, setFilterYear] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isInitialApiCall, setisInitialApiCall] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalData, setTotalData] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [headerData, setHeaderData] = useState([]);
  const [activityListLoader, setActivityListLoader] = useState(false);

  const params = useParams();
  console.log("paramsActivity", params);

  useEffect(() => {
    if (params.activity_id) {
      setActivities(params.activity_id);
    } else {
      setActivities("All");
    }
  }, [params.activity_id]);

  useEffect(() => {
    if (activities) {
      fetchDatafromDropdown(activities);
    }
  }, [searchData, page, rowsPerPage, activities]);

  const handleChangeActivity = (event) => {
    setActivities(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await fetchNavbarData({
        url: process.env.REACT_APP_API_URL,
      });
      if (response.data.status == true) {
        setHeaderData(response.data.responseBody.nav_activity);

        console.log(
          "activityIdactivityId",
          response?.data?.responseBody?.nav_activity
        );
        console.log(
          "response.data.responseBody.site",
          response.data.responseBody.site
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (year === "All") {
      if (isInitialApiCall === false) {
        fetchDatafromDropdown(activities);
      } else {
        setisInitialApiCall(false);
        // isInitialApiCall = false;
      }
    } else {
      fetchDatafromDropdown(activities);
    }
  }, [year]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    // <label color="text.primary" className="active-link-color">
    //   Activities
    // </label>,
    {
      label: "Activities",
      url: `/activities`,
    },

    <label color="text.primary" className="active-link-color">
      {params.name}
    </label>,
  ];

  const navigate = useNavigate();

  // const handleClick = (id, date) => {
  //   navigate(`/activities-detail/${params.activity_id}/${id}`);
  // };

  const handleClick = (activityId, id, image, date) => {
    navigate(`/activities-detail/${activityId}/${id}`, {
      state: { image, date },
    });
    console.log("params.activity_id}");
  };

  const fetchDatafromDropdown = async (id) => {
    try {
      setActivityListLoader(true);
      var data = {
        url: process.env.REACT_APP_API_URL,
        page: "activity",
      };

      if (year !== "All") {
        data["year"] = year;
      }

      if (id !== "All") {
        data["activity_id"] = id;
      }

      const response = await fetchActivitiyList(
        data,
        page,
        rowsPerPage,
        searchData
      );
      setActivityListLoader(false);

      const responseBody = response.data.responseBody;
      setTotalData(response.data.responseBody.total);

      // working==============
      if (response.data.responseBody.list.length) {
        setApiData(response.data.responseBody.list);
      } else {
        setPage((prev) => prev - 1);
      }

      setFilterActivities(
        Object.values(response.data.responseBody.filter_activity)
      );
      setFilterYear(response.data.responseBody.filter_year);
    } catch (error) {
      setActivityListLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   fetchDatafromDropdown();
  // }, []);

  useEffect(() => {
    const fetchBanner = async () => {
      setLoading(true);
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "activity",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  // const handleSearchActivity = (e) => {
  //   setSearchActivity(e.target.value);
  //   console.log("");
  // };

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>

        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      {!imageLoaded && <FullpageLoader />}
      <Container className="sub_activities">
        <div className="first_div">
          <div className="second-div">
            {/* <div> */}
            {/* <FormControl sx={{ m: 1, minWidth: 220 }}>
                <Select
                  value={year}
                  onChange={handleChangeYear}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="activities-width"
                >
                  <MenuItem value="All">All</MenuItem>
                  {filterYear.map((yearItem) => (
                    <MenuItem key={yearItem} value={yearItem}>
                      {yearItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            <select
              value={year}
              onChange={handleChangeYear}
              aria-label="Without label"
              // className="activities-width"
              className="activities-selection"
              // style={{ margin: "8px", minWidth: "220px" }}
            >
              <option value="All">All</option>
              {filterYear.map((yearItem) => (
                <option key={yearItem} value={yearItem}>
                  {yearItem}
                </option>
              ))}
            </select>
            {/* </div> */}
            {/* <div> */}
            {/* <FormControl sx={{ m: 1, minWidth: 220 }}>
                <Select
                  value={activities}
                  onChange={handleChangeActivity}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="activities-width"
                >
                  <MenuItem value="All">All</MenuItem>
                  {headerData.map((activity) => (
                    <MenuItem
                      key={activity.id}
                      id={activity.id}
                      value={activity.id}
                    >
                      {activity.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            <select
              value={activities}
              onChange={handleChangeActivity}
              aria-label="Without label"
              // className="activities-width"
              className="activities-selection"
              // style={{ margin: "8px", minWidth: "220px" }}
            >
              <option value="All">All</option>
              {headerData.map((activity) => (
                <option key={activity.id} value={activity.id}>
                  {activity.name}
                </option>
              ))}
            </select>
            {/* </div> */}
          </div>
          {/* <div className="activity_search_wrap">
            <input
              type="search"
              className="search_input_activity"
              placeholder="Search.."
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div> */}

          <div className="search-container">
            <IoSearch className="search-icon1" />
            <input
              type="search"
              className="search-input"
              placeholder="Search.."
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
        </div>
      </Container>
      <Container>
        <hr></hr>

        {Array.isArray(apiData) && apiData.length > 0 ? (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="sub-activities"
          >
            {apiData.map((activity, index) => {
              const date = new Date(activity.activity_date);
              const options = { day: "numeric", month: "short" };
              const formattedDate = date.toLocaleDateString("en-US", options);
              const day = date.getDate();
              const month = formattedDate.split(" ")[0];

              return (
                <Grid item xs={6} sm={6} md={3} key={index}>
                  <Card
                    className="activities-inner-content-wrap-sub-actvities"
                    // onClick={() =>
                    //   handleClick(
                    //     activity.activity_id,
                    //     activity.id,
                    //     activity.image,
                    //     formattedDate
                    //   )
                    // }
                  >
                    {/* working */}
                    {/* <img
                      src={
                        activity
                          ? activity.thumbnail_image !== ""
                            ? activity.thumbnail_image
                            : imageNotFound
                          : imageNotFound
                      }
                      alt={activity ? activity?.title : "No Image Found"}
                      className="activities-img"
                      onClick={() =>
                        handleClick(
                          activity.activity_id,
                          activity.id,
                          activity.image,
                          formattedDate
                        )
                      }
                      onError={(e) => {
                        e.target.src = imageNotFound; // Set src to imageNotFound if there is an error loading the image
                      }}
                    /> */}
                    <LazyLoadImage
                      src={
                        activity
                          ? activity.thumbnail_image !== ""
                            ? activity.thumbnail_image
                            : imageNotFound
                          : imageNotFound
                      }
                      alt={activity ? activity.title : "No Image Found"}
                      className="activities-img"
                      wrapperClassName="lazy-load-image-background aboutustype"
                      afterLoad={() => {
                        const image = document.querySelector(
                          `.lazy-load-image-background[data-src="${
                            activity ? activity.thumbnail_image : imageNotFound
                          }"]`
                        );
                        if (image) {
                          image.classList.add("lazy-load-image-loaded");
                        }
                      }}
                      onClick={() =>
                        handleClick(
                          activity.activity_id,
                          activity.id,
                          activity.image,
                          formattedDate
                        )
                      }
                      onError={(e) => {
                        e.target.src = imageNotFound;
                      }}
                    />

                    {/* <img
                      src={
                        activity.image != "" ? activity.image : imageNotFound
                      }
                      alt={activity.title}
                      className="activities-img"
                    /> */}
                    {/* <img src={image1} alt="" className="activities-img" /> */}

                    <div className="activities-inner-content-sub-actvities">
                      <p
                        className="sub-activites-name"
                        // onClick={() =>
                        //   handleClick(
                        //     activity.activity_id,
                        //     activity.id,
                        //     activity.image,
                        //     formattedDate
                        //   )
                        // }
                        onClick={() => setActivities(activity.activity_id)}
                      >
                        {activity.name}
                      </p>
                      <h4
                        className="sub-activities-title"
                        onClick={() =>
                          handleClick(
                            activity.activity_id,
                            activity.id,
                            activity.image,
                            formattedDate
                          )
                        }
                      >
                        {activity.title}
                      </h4>
                      <h6
                        className="sub-activities-desc"
                        // onClick={() => setActivities(activity.activity_id)}
                        onClick={() =>
                          handleClick(
                            activity.activity_id,
                            activity.id,
                            activity.image,
                            formattedDate
                          )
                        }
                      >
                        {activity.short_description}
                      </h6>
                    </div>
                    <div className="activities-date">
                      <div>
                        <h5>{day}</h5>
                      </div>
                      <div>
                        <p>{month}</p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : activityListLoader ? (
          <ActivityLoader />
        ) : (
          <div className="no-data mt-5">No data available</div>
        )}

        {activityListLoader ? (
          <div></div>
        ) : (
          <TablePagination
            rowsPerPageOptions={[12, 25, 50]}
            className="pagination-activities"
            component="div"
            count={totalData}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value));
              setPage(0);
            }}
          />
        )}
      </Container>
    </>
  );
};

export default SubActivities;
