import React, { useState, useEffect, useRef } from "react";
import image from "../../assets/images/subheader.jpg";
import CommonBreadcrumbs from "../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { isValidPhoneNumber } from "libphonenumber-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchDonationList,
  retrieveInfo,
  sendOtp,
  verifyOtp,
  countryData,
  paymentData,
  fetchSlider,
} from "../../api/API";
import "./Donation.css";
import parsePhoneNumber from "libphonenumber-js";
import { Sync } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Loader from "../../common/Loader/Loader";
import InnerpageLoader from "../Home/InnerpageLoader";
import FullpageLoader from "../../common/HomeSliderLoader/FullpageLoader";

const getFormattedPrice = (price) => `${price.toFixed(2)}`;

const breadcrumbsData = [
  { label: "Home", url: "/" },
  <Typography color="text.primary" className="active-link-color">
    Donation
  </Typography>,
];

export default function DonationNew() {
  const [donationList, setDonationList] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [total, setTotal] = useState(0);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherDonationValue, setOtherDonationValue] = useState(0);

  const [retrieveInformation, setRetrieveInformation] = useState("");
  const [response, setResponse] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState("");
  // const [showResendButton, setShowResendButton] = useState(false);
  // const [secondsLeft, setSecondsLeft] = useState(20);
  const modalRef = useRef(null);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountryISO, setSelectedCountryISO] = useState("in"); // State to store selected country ISO code
  const [selectedDonationId, setSelectedDonationId] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState();
  const [otpError, setOtpError] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    email: "",
    mobileNumber: "",
    note: "",
    address: "",
  });
  const [branchName, setBranchName] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;

  console.log();

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState("Aadhar card");
  const [cardNumber, setCardNumber] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isValidationVisible, setIsValidationVisible] = useState(false);

  const handleCardTypeChange = (e) => {
    setCardType(e.target.value);
    setCardNumber("");
    setValidationMessage("");
    setIsValidationVisible(false);
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value;
    // setCardNumber(e.target.value);
    // validateCard(e.target.value, cardType);
    setCardNumber(value);
    if (value === "") {
      setValidationMessage("");
      setIsValidationVisible(false);
    } else {
      validateCard(value, cardType);
    }
  };

  const validateCard = (number, type) => {
    if (type === "Aadhar card") {
      if (validateAadhar(number)) {
        setValidationMessage("Valid Aadhar card number");
      } else {
        setValidationMessage("Invalid Aadhar card number");
      }
    } else if (type === "Pan card") {
      if (validatePan(number)) {
        setValidationMessage("Valid PAN card number");
      } else {
        setValidationMessage("Invalid PAN card number");
      }
    }
    setIsValidationVisible(true);
  };

  const validateAadhar = (aadharNumber) => {
    const aadharPattern = /^\d{12}$/;
    return aadharPattern.test(aadharNumber);
  };

  const validatePan = (panNumber) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panPattern.test(panNumber);
  };

  const notify = (msg) => {
    toast.error(msg, {
      position: "top-right",
    });
  };

  const successNotify = (msg) => {
    toast.success(msg, {
      position: "top-right",
    });
  };

  useEffect(() => {
    console.log("donationList...", donationList);
  }, [donationList]);

  const resetSelectedDonorId = () => {
    const updatedCheckedState = checkedState.map((item, index) => false);
    console.log("updatedCheckedState", updatedCheckedState);
    setCheckedState(updatedCheckedState);
  };

  //donation list - checkbox
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    console.log("updatedCheckedState", updatedCheckedState);
    setCheckedState(updatedCheckedState);

    // Create object with IDs of selected checkboxes
    const newSelectedIds = {};
    updatedCheckedState.forEach((isChecked, idx) => {
      if (isChecked) {
        newSelectedIds[donationList[idx].id] = parseInt(
          donationList[idx].amount
        );
      }
    });
    setSelectedDonationId(newSelectedIds);

    // Total amount
    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + parseFloat(donationList[index].amount);
        }
        return sum;
      },
      0
    );
    console.log("otherDonationValue.....", otherDonationValue);
    console.log("otherDonationValue....typeof.", typeof otherDonationValue);
    if (otherDonationValue) {
      setTotal(parseFloat(totalPrice) + parseFloat(otherDonationValue));
    } else {
      setTotal(parseFloat(totalPrice));
    }
  };

  const handleKeyDown = (event) => {
    // Check if decrement key (arrow down) is pressed
    if (event.key === "ArrowDown" && otherDonationValue <= 0) {
      event.preventDefault(); // Prevent default action (decrementing)
    }
  };

  // Other - donation - input
  const handleOtherDonationChange = (e) => {
    console.log("e.target.value..", e.target.value);
    const totalPrice = checkedState.reduce((sum, currentState, index) => {
      if (currentState === true) {
        return sum + parseFloat(donationList[index].amount);
      }
      return sum;
    }, 0);

    const inputValue = parseInt(e.target.value);

    // Prevent setting negative values
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOtherDonationValue(inputValue);
      setTotal(totalPrice + inputValue);
    } else {
      // If negative, set amount to 0
      setOtherDonationValue(0);
      setTotal(totalPrice);
    }
  };

  //Other - donation - checkbox
  const handleOtherDonationCheckboxChange = (e) => {
    if (!e.target.checked) {
      // Subtract other donation value from total when checkbox is unchecked
      const otherDonation = parseFloat(otherDonationValue);
      if (!isNaN(otherDonation)) {
        setTotal(total - otherDonation);
      }
      setOtherDonationValue(""); // Reset other donation value
    }
    setShowOtherInput(e.target.checked);
  };

  //Donation - list - API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDonationList({
          url: apiUrl,
          page: "donation",
        });
        if (
          response.data.responseBody &&
          Array.isArray(response.data.responseBody.donations)
        ) {
          const initialCheckedState = new Array(
            response.data.responseBody.donations.length
          ).fill(false);
          setDonationList(response.data.responseBody.donations);
          setCheckedState(initialCheckedState);
          setCurrencySymbol(response.data.responseBody.currency);
          console.log("donations", response.data.responseBody.currency);
          console.log("donationList", response.data.responseBody.donations);
        } else {
          console.error("Invalid data format:", response.data.responseBody);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Retrieve - Info
  const handleRetrieveInfo = async () => {
    try {
      if (!emailPattern.test(retrieveInformation)) {
        notify("Please enter valid email address");
        return;
      }

      const data = {
        url: apiUrl,
        page: "donation",
        email: retrieveInformation,
      };
      const response = await retrieveInfo(data);
      if (response.data.responseBody.donaterData.length === 0) {
        notify("No data found please fill up the form");
        return;
      }

      if (response.data.responseBody.verify_status === false) {
        setResponse(response);
        notify("Please verify email");
        return;
      }

      setIsVerified(response.data.responseBody.verify_status);

      console.log("Response from retrieve info", response.data.responseBody);
      setResponse(response);
    } catch (error) {
      console.error("Error sending email data", error);
    }
  };

  //Terms and condition page
  const handleClick = (event) => {
    event.preventDefault();
    // navigate("/terms-conditions");
    window.open("/terms-conditions", "_blank");
  };

  const handleRetrieveInformation = (event) => {
    setRetrieveInformation(event.target.value);
    if (response !== null) {
      clearForm();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //Send - OTP
  const handleSendOtp = async () => {
    console.log("Response from AP1");
    if (!isValidEmail) {
      notify("Please enter a valid email address");
      return;
    }
    console.log("Response from AP2 ==> ", formData.email);
    if (!formData.email.trim()) {
      return;
    }
    console.log("Response from AP3");

    if (seconds !== 20) {
      setSeconds(20);
    }
    console.log("Response from AP4");
    try {
      const data = {
        url: apiUrl,
        page: "donation",
        email: formData.email,
      };

      const response = await sendOtp(data);
      setOtpError(null);
      console.log("Response from API", response);
    } catch (error) {
      if (error.response?.data?.code === 422) {
        setOtpError(error?.response?.data?.message?.toString());
        notify(error?.response?.data?.message?.toString());
        return;
      }
      console.error("Error sending email data", error);
    }
  };

  const handleVerifyButton = () => {
    if (formData.email !== "") {
      handleSendOtp();
      setShowModal(true);
    }
  };

  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
  };

  //Verify - OTP
  // const handleVerifyOTP = async () => {
  //   try {
  //     if (!otp.trim()) {
  //       notify("Please enter otp");
  //       return;
  //     }

  //     const data = {
  //       url: apiUrl,
  //       page: "donation",
  //       email: email,
  //       otp: otp,
  //     };

  //     const response = await verifyOtp(data);
  //     if (response.data.status === true) {
  //       setShowModal(false);
  //       successNotify("Email verified successfully");
  //       setSeconds(60);
  //       setOtp("");
  //     }

  //     console.log("Response from API", response);
  //     console.log("OTP", response.data.status);
  //     setIsVerified(true);
  //     localStorage.setItem("verifiedEmail", email);
  //   } catch (error) {
  //     console.error("Error sending email data", error);
  //   }
  // };

  //Verify - OTP
  const handleVerifyOTP = async () => {
    try {
      if (!otp.trim()) {
        notify("Please enter otp");
        return;
      }
      const data = {
        url: apiUrl,
        page: "donation",
        email: formData.email,
        otp: otp,
      };
      const response = await verifyOtp(data);
      if (response.data.status === true) {
        console.log(
          "response.data.responseBody.email",
          response.data.requestBody.email
        );
        setShowModal(false);
        successNotify("Email verified successfully");
        localStorage.setItem("verfiedEmail", response.data.requestBody.email);
        setIsVerified(true);
        setOtp("");
      } else {
        notify("Invalid OTP Please enter a valid OTP");
      }
    } catch (error) {
      console.log(error);
      notify(
        error?.response?.data?.message ?? "Invalid OTP Please enter a valid OTP"
      );
    }
  };

  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleEmailChange = (event) => {
    const value = event.target.value;

    const verfiedEmail = localStorage.getItem("verfiedEmail");
    if (value === verfiedEmail) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }

    // setEmail(value);
    setIsValidEmail(emailPattern.test(value));
    setFormData({ ...formData, email: value });
    console.log(formData);
  };

  // Set retrieve - info data
  useEffect(() => {
    if (
      response &&
      response.data &&
      response.data.responseBody.donaterData.length > 0
    ) {
      const data = response.data.responseBody.donaterData[0];
      setFormData({
        firstName: data.first_name || "",
        lastName: data.last_name || "",
        country: data.country || "",
        email: data.email || "",
        mobileNumber: data.mobile_number || "",
        note: "",
        address: "",
      });
    }
  }, [response]);
  // console.log("country", formData.country);

  //Country - list
  const getCountryList = async () => {
    try {
      const response = await countryData();
      setCountryList(response.data.responseBody);
      // console.log("Response country", response.data.responseBody);
    } catch (error) {
      console.error("Error sending email data", error);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  //Paymet  - API
  const handlePayment = async () => {
    try {
      if (isEmpty(selectedDonationId) && !otherDonationValue) {
        // alert("Please enter or select donation");
        notify("Please enter or select donation");
        return;
      }

      console.log("formdata....", formData.firstName);
      console.log("formdata....", formData.lastName);
      console.log("formdata....", formData.email);
      console.log("formdata....", formData.mobileNumber, formData.mobileNumber);

      // Validation
      if (
        !formData.firstName ||
        !formData.lastName ||
        !formData.email ||
        !formData.mobileNumber ||
        !formData.address
        // !formData.note
      ) {
        notify("Please fill personal information");
        return;
      }
      console.log("formData.mobileNumber", formData.mobileNumber);

      const finalMobilenumber = formatMobileNumber(formData.mobileNumber);

      console.log("finalMobilenumber", finalMobilenumber);

      const obj = parsePhoneNumber(finalMobilenumber);

      console.log("obj", obj);

      if (obj?.isValid() === false) {
        notify("Please enter valid mobile number");
        return;
      }

      if (!isVerified) {
        notify("Please verify your email");
        return;
      }

      if (showOtherInput === true) {
        setSelectedDonationId({
          ...selectedDonationId,
          others: otherDonationValue,
        });
      }

      const data = {
        url: process.env.REACT_APP_API_URL,
        page: "donation",
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        country: formData.country,
        mobile_number: finalMobilenumber,
        amount: total,
        note: formData.note,
        address: formData.address,
        donation_ids:
          showOtherInput && otherDonationValue
            ? {
                ...selectedDonationId,
                others: otherDonationValue,
              }
            : selectedDonationId,
      };

      const response = await paymentData(data);
      console.log("Response from Payment", response);
      console.log("payment api", response.data.responseBody.url);
      if (response.data.status === true) {
        // window.open(response.data.responseBody.url);
        window.location.href = response.data.responseBody.url;
        clearAllField();
      }
    } catch (error) {
      console.error("Error", error);
      if (error.response?.data?.code === 422) {
        notify(error?.response?.data?.message[0]?.toString());
        return;
      }
      notify("Please verify your email");
    }
  };

  function formatMobileNumber(number) {
    // Remove all '+' symbols
    let formattedNumber = number.replace(/\+/g, "");

    // Add one '+' symbol at the beginning if it's not there
    if (formattedNumber.charAt(0) !== "+") {
      formattedNumber = "+" + formattedNumber;
    }

    return formattedNumber;
  }

  const clearAllField = () => {
    setFormData({
      firstName: "",
      lastName: "",
      country: "",
      email: "",
      mobileNumber: "",
      note: "",
      address: "",
    });
    setIsValidEmail(false);
    setSelectedDonationId({});
    resetSelectedDonorId();
    setTotal(0);
    setRetrieveInformation("");
    setIsVerified(false);
    setResponse(null);
  };

  const clearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      country: "",
      email: "",
      mobileNumber: "",
      note: "",
      address: "",
    });
    setIsValidEmail(false);
    setIsVerified(false);
    setResponse(null);
  };

  const handleChangeFirstName = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, firstName: value });
    console.log(formData);
  };

  const handleChangeLastName = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, lastName: value });
    console.log(formData);
  };

  // const handleChangeMobileNumber = (e) => {
  //   const value = e.target.value;
  //   setFormData({ ...formData, mobileNumber: value });
  //   console.log(formData);
  // };

  const setPhone = (phone) => {
    setFormData((prevData) => ({
      ...prevData,
      mobileNumber: phone,
    }));
  };

  const handleChangeNote = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, note: value });
    console.log(formData);
  };

  const handleChangeAddress = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, address: value });
    console.log(formData);
  };
  // const handleChangeInfo = (e) => {
  //   const inputId = e.target.id;
  //   const inputName = e.target.name;
  //   console.log("InputId", inputId);
  //   console.log("InputName", inputName);
  // };

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "donation",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);
  const textFieldRef = useRef(null);
  const buttonRef = useRef(null);

  const handleTextFieldKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent default tab behavior
      buttonRef.current.focus(); // Focus on the button
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const foundCountry = countryList.find(
      (country) => country.name === selectedCountry
    );
    if (foundCountry) {
      setFormData({
        ...formData,
        country: selectedCountry, // Update the country name
      });
      setSelectedCountryISO(foundCountry.iso.toLowerCase()); // Update the selected ISO code
    }
  };

  const handleBranchChange = (e) => {
    setBranchName(e.target.value);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>

        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      {!imageLoaded && <FullpageLoader />}
      <ToastContainer />
      <Container className="donation-page">
        {/* ==================== Branch Selection start  ==================== */}
        {/* <select
          className="form-select branch-selection mb-3"
          aria-label="Default select example"
          value={branchName}
          onChange={handleBranchChange}
        >
          <option value="" disabled hidden>
            Branch Selection
          </option>
          <option value="">Branch Selection</option>
          <option value="Branch1">Branch1</option>
          <option value="Branch2">Branch2</option>
          <option value="Branch3">Branch3</option>
        </select> */}
        {/* ==================== Branch Selection end  ==================== */}
        <div className="">
          <Accordion defaultExpanded className="donation-title">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="donation-icons" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="donation-subtitle"
            >
              Donate to Gurukul Seva
            </AccordionSummary>
            <Accordion defaultExpanded className="donation-list">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="donation-icon" />}
                aria-controls="panel3-content"
                id="panel3-header"
                className="donation-text"
              >
                Donation
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0} className="donation-list pb-2">
                  <div className="toppings-list">
                    {/* ==================== Donation list start  ==================== */}
                    {donationList.map(({ title, amount }, index) => {
                      return (
                        <div key={index} className="donationlist-wrap">
                          <div className="toppings-list-item">
                            <div className="left-section">
                              <input
                                type="checkbox"
                                id={`custom-checkbox-${index}`}
                                name={title}
                                value={title}
                                checked={checkedState[index]}
                                onChange={() => handleOnChange(index)}
                                className="donation-check-box"
                              />
                              <label
                                htmlFor={`custom-checkbox-${index}`}
                                className="donation-list-name"
                              >
                                {title}
                              </label>
                              <div className="right-section">
                                {currencySymbol}
                                {getFormattedPrice(parseFloat(amount))}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/*  ==================== Donation list end  ==================== */}
                    {/* ==================== Other donation start ==================== */}
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id="other-donation-checkbox"
                        name="other-donation"
                        value="other-donation"
                        checked={showOtherInput}
                        onChange={handleOtherDonationCheckboxChange}
                        className="donation-check-box"
                      />
                      <label
                        htmlFor="other-donation-checkbox"
                        className="donation-list-name"
                      >
                        Other Donation
                      </label>
                      {showOtherInput && (
                        <div>
                          <div className="toppings-list-item">
                            <div className="left-section">
                              <input
                                type="number"
                                value={otherDonationValue}
                                onChange={handleOtherDonationChange}
                                placeholder="Enter amount"
                                onKeyDown={handleKeyDown}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/*  ==================== Other donation end  ==================== */}
                  </div>
                </Grid>
                {/* ==================== Selected donation list start ==================== */}
                <div>
                  <div className="donation-summary-title">
                    <div className="">DONATION SUMMARY:</div>
                  </div>
                  <div className="right-section">
                    {donationList.map(({ title, amount }, index) => {
                      if (checkedState[index]) {
                        return (
                          <>
                            <div className="right-section-donation">
                              <div key={index}>
                                <p>{title}</p>
                              </div>
                              <div className="">
                                <p>
                                  {currencySymbol}
                                  {getFormattedPrice(parseFloat(amount))}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      }
                      return null;
                    })}
                    {showOtherInput && (
                      <div className="donation-summary-item">
                        <div className="">
                          <p>Other Donation</p>
                        </div>
                        <div className="donation-amount">
                          <p>
                            {isNaN(parseFloat(otherDonationValue))
                              ? `${currencySymbol}0.00`
                              : `${currencySymbol}${getFormattedPrice(
                                  parseFloat(otherDonationValue)
                                )}`}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* ==================== Selected donation list end ==================== */}
                {/* ==================== Total amount start ==================== */}
                <div>
                  <div className="donation-total">
                    <div>Total:</div>
                    <div>
                      {currencySymbol}
                      {getFormattedPrice(total)}
                    </div>
                  </div>
                </div>
                {/* ==================== Total amount end ==================== */}
              </AccordionDetails>
            </Accordion>
          </Accordion>
        </div>
      </Container>

      {/*  ====================  Retrieve Information start  ====================  */}
      <Container>
        <div className="donation-input-wrap">
          <div></div>
          <div
            className="donation-phone-input"
            // style={{ position: "relative", width: "43%" }}
          >
            <label className="retrieve-info-label">
              Enter your mobile number
            </label>
            <PhoneInput
              country={selectedCountryISO}
              enableSearch={true}
              value={formData.mobileNumber}
              onChange={(phone) => setPhone(phone)}
              // className="phone-input"
              countryCodeEditable={false}
              inputStyle={{
                width: "100%",
                paddingRight: "40px",
                height: "37px",
                marginBottom: "0px",
              }}
              // className="donation-mobile-input"
            />
            <button className="btn1">Send otp</button>
          </div>
          <div className="input-group donation-otp">
            <input
              inputRef={textFieldRef}
              type="email"
              class="form-control"
              placeholder="Enter OTP"
              aria-label="Enter OTP"
              aria-describedby="basic-addon2"
              value={retrieveInformation}
              onChange={handleRetrieveInformation}
              onKeyDown={handleTextFieldKeyDown}
            />
            <div className="input-group-append">
              <button
                ref={buttonRef}
                className="input-group-text donation-verify-btn"
                id="basic-addon2"
                onClick={handleRetrieveInfo}
              >
                Verify{" "}
              </button>
            </div>
          </div>
          <div className="donaion-resend-btn-wrap">
            <button className="donation-resend-otp-btn">Resend OTP</button>
          </div>
        </div>
        <select
          className="form-select branch-selection my-3"
          aria-label="Default select example"
          value={branchName}
          onChange={handleBranchChange}
        >
          <option value="">Please select a member</option>
          <option value="Member1">Member1</option>
          <option value="Member2">Member2</option>
          <option value="Member3">Member3</option>
        </select>
        {/* ============================ */}
        {/* <div className="donation-selection-wrap">
          <div className="donation-selection">
            <div>
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  bottom: "0",
                  display: "flex",
                  alignItems: "center",
                  zIndex: 1,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  height: "37px",
                }}
              >
                <select
                  style={{
                    height: "100%",
                    border: "none",
                    backgroundColor: "#08416b",
                    color: "#fff",
                    outline: "none",
                    padding: "0 10px",
                  }}
                >
                  <option value="Aadhar card">Aadhar card</option>
                  <option value="Aadhar card">Aadhar card</option>
                  <option value="Aadhar card">Aadhar card</option>
                </select>
              </div>
              <input
                type="text"
                style={{
                  width: "100%",
                  paddingLeft: "70px",
                  boxSizing: "border-box",
                  height: "37px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Note"
              style={{
                width: "100%",
                height: "37px",
                border: "1px solid #ccc",
                paddingLeft: "10px",
              }}
            />
          </div>
        </div>
        <div className="donation-terms pt-3">
          <div>
            <FormGroup className="doasnation-checkbox">
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                className="donation-checkbox"
              />
            </FormGroup>
          </div>
          <div>
            <span class="terms-condition">
              I Agree to{" "}
              <a
                onClick={handleClick}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Condition for Donation
              </a>
            </span>
          </div>
        </div>
        <div className="payment-menothod-wrap">
          <Grid container spacing={3} className="donation-buttons pt-4">
            <Grid item xs={12} sm={6} md={3}>
              <div className="payment-inner-wrap">
                <div>
                  <h6>Total Donation</h6>
                </div>
                <div>
                  <h6>
                    {currencySymbol}
                    {getFormattedPrice(total)}
                  </h6>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="payment-btn-wrap">
                <button className="payment-btn" onClick={handlePayment}>
                  Proceed To Payment
                </button>
              </div>
            </Grid>
          </Grid>
        </div> */}
      </Container>

      {/*  ====================  Retrieve Information end  ====================  */}
      <Container>
        <div className="OR">OR</div>
      </Container>
      {/*  ==================== Personal Info start  ==================== */}
      <Container className="donation-info">
        <Card className="donation-form-wrap">
          <h2>Personal Information</h2>
          <div>
            <div className="donation-form">
              <div>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <FaUser className="donation-icon" />
                  </span>

                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    // class="form-control donation-input"
                    className="form-control donation-input "
                    placeholder="First Name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={formData.firstName}
                    onChange={handleChangeFirstName}
                    // onChange={(e) =>
                    //   setFormData({ ...formData, firstName: e.target.value })
                    // }
                  />
                </div>
              </div>

              <div>
                {/* <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <FaUser className="donation-icon" />
                  </span>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    class="form-control donation-input"
                    placeholder="Last Name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={formData.lastName}
                    // onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    onChange={handleChangeLastName}
                  />
                </div> */}
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <FaUser className="donation-icon" />
                  </span>
                  <input
                    type="text"
                    id="middlename"
                    name="middlename"
                    class="form-control donation-input"
                    placeholder="Middle Name"
                    aria-label="middlename"
                    aria-describedby="basic-addon1"
                    value={formData.lastName}
                    // onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    onChange={handleChangeLastName}
                  />
                </div>
              </div>
              <div>
                {/* ==================== Country List start ==================== */}
                {/* <select
                  className="form-select donation-country mb-3"
                  aria-label="Default select example"
                  value={formData.country}
                  onChange={handleCountryChange}
                >
                  <>
                    <option value="">Select Country</option>
                    {countryList.map(
                      (country) =>
                        country.name && (
                          <option key={country.name} value={country.name}>
                            {country.name}
                          </option>
                        )
                    )}
                  </>
                </select> */}
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <FaUser className="donation-icon" />
                  </span>
                  <input
                    type="text"
                    id="surname"
                    name="surname"
                    class="form-control donation-input"
                    placeholder="Sur Name"
                    aria-label="surname"
                    aria-describedby="basic-addon1"
                    value={formData.lastName}
                    // onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    onChange={handleChangeLastName}
                  />
                </div>
                {/* ==================== Country List end ==================== */}
              </div>
            </div>
          </div>

          <div className="tt">
            <div className="ff">
              <div className="donation-aadharcard-dropdown">
                <div>
                  <div className="donation-card-selection">
                    <select
                      onChange={handleCardTypeChange}
                      value={cardType}
                      className="dropdown-aadharcard"
                    >
                      <option value="Aadhar card">Aadhar card</option>
                      <option value="Pan card">Pan card</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    className="donation-aadhar-input"
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                  />
                </div>
                <p
                  className="donation-validation-message1"
                  style={{
                    marginTop: isValidationVisible ? "-22px" : "0px",
                    visibility: isValidationVisible ? "visible" : "hidden",
                    color: "red",
                    fontSize: "14px",
                  }}
                >
                  {validationMessage}
                </p>
              </div>
              <div>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <MdEmail className="donation-icon" />
                  </span>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="form-control donation-input"
                    style={{ borderRadius: "0px 6px 6px 0px" }}
                    placeholder="Email"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    // value={email}
                    value={formData.email}
                    onChange={handleEmailChange}
                  />

                  {isVerified ? (
                    <MdVerified className="sendotp-btn verified" />
                  ) : (
                    <>
                      <button
                        onClick={handleVerifyButton}
                        className="sendotp-btn"
                      >
                        Verify
                      </button>
                    </>
                  )}

                  {/* ==================== Verify OTp Modal start ==================== */}
                  {showModal && (
                    <div className="modal-overlay">
                      <div className="modal" ref={modalRef}>
                        <div className="modal-content">
                          <span className="close" onClick={handleCloseModal}>
                            &times;
                          </span>
                          <div className="verify-otp-wrap">
                            <div>
                              <h2>Please Enter Valid OTP</h2>
                            </div>
                            <div>
                              <input
                                type="text"
                                class="form-control verify-input"
                                aria-label="Username"
                                name="otp"
                                aria-describedby="basic-addon1"
                                value={otp}
                                onChange={handleChangeOtp}
                              />
                            </div>
                          </div>
                          <div className="otp-verification-btn">
                            <div>
                              <button
                                onClick={handleCloseModal}
                                className="cancel-btn"
                              >
                                Cancel
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={handleVerifyOTP}
                                className="verify-btn"
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                          {/* ================== */}
                          {/* <div className="otp-verification-btn">
                            <>
                              <div>
                                <button
                                  onClick={handleCloseModal}
                                  className="cancel-btn"
                                >
                                  Cancel
                                </button>
                              </div>
                              {showResendButton ? (
                                <button
                                  className="verify-btn"
                                  onClick={handleVerifyOTP}
                                >
                                  Resend OTP
                                </button>
                              ) : (
                                <div>
                                  <button
                                    onClick={handleVerifyOTP}
                                    className="verify-btn"
                                  >
                                    Verify
                                  </button>
                                </div>
                              )}
                            </>
                          </div> */}
                          {/* ================== */}
                          {otpError ? (
                            <div
                              style={{ textAlign: "center" }}
                              className="mt-4"
                            >
                              {otpError}
                            </div>
                          ) : (
                            <div className="countdown-text d-flex justify-content-center pt-4">
                              {seconds > 0 ? (
                                <>
                                  <p className="d-flex">
                                    Please wait for{" "}
                                    {seconds < 10 ? `0${seconds}` : seconds}{" "}
                                    seconds
                                  </p>
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "220px",
                                  }}
                                  className="abc"
                                >
                                  <div>Didn't recieve OTP?</div>
                                  <div>
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        textDecorationLine: "underline",
                                        fontWeight: 600,
                                        color: "#08416b",
                                      }}
                                      onClick={handleSendOtp}
                                    >
                                      Resend
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ==================== Verify OTp Modal end ==================== */}
                </div>
              </div>
              <div>
                {/* <PhoneInput
                  // alwaysDefaultMask={true}
                  country={selectedCountryISO}
                  enableSearch={true}
                  value={formData.mobileNumber}
                  onChange={(phone) => setPhone(phone)}
                  className="phone-input"
                  countryCodeEditable={false}
                /> */}
              </div>
              <div className="donation-terms">
                <div>
                  <FormGroup className="doasnation-checkbox">
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      className="donation-checkbox"
                    />
                  </FormGroup>
                </div>
                <div>
                  <span class="terms-condition">
                    I Agree to{" "}
                    <a
                      onClick={handleClick}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Condition for Donation
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="donation-note-wrap">
              <div class="form-floating mb-3">
                <textarea
                  class="form-control donation-textarea"
                  id="address"
                  name="address"
                  placeholder="Leave a comment here"
                  value={formData.address}
                  onChange={handleChangeAddress}
                  maxLength={500}
                ></textarea>
                <label for="floatingTextarea">Address</label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control donation-textarea"
                  id="note"
                  name="note"
                  placeholder="Leave a comment here"
                  value={formData.note}
                  onChange={handleChangeNote}
                  maxLength={500}
                ></textarea>
                <label for="floatingTextarea">Note</label>
              </div>
            </div>
          </div>
          <div className="payment-menothod-wrap">
            <Grid container spacing={3} className="donation-buttons pt-4">
              <Grid item xs={12} sm={6} md={3}>
                <div className="payment-inner-wrap">
                  <div>
                    <h6>Total Donation</h6>
                  </div>
                  <div>
                    <h6>
                      {currencySymbol}
                      {getFormattedPrice(total)}
                    </h6>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="payment-btn-wrap">
                  <button className="payment-btn" onClick={handlePayment}>
                    Proceed To Payment
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Container>
      {/*  ==================== Personal Info end  ==================== */}
    </>
  );
}
